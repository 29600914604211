
import axios from 'axios'
import $ from "jquery";
export default ({
    state: {
    },
    getters: {
    },
    actions: {
        actReSyncDataSale({ dispatch, rootState }){
            if(rootState.connectionStatus == "Online"){
                //get data sale
                var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
                pendingsale = pendingsale.filter((sale) => {
                    return (sale.SynMode == "1");
                }); 
                for (var i = 0; i < pendingsale.length; i++) {
                    dispatch('actSyncDataSale', pendingsale[i].TransactionID + "." + pendingsale[i].DeviceNo);
                }

                //get data cashbank
                var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
                cashbank = cashbank.filter((cash) => {
                    return (cash.SynMode == "1" && cash.DeviceNo == rootState.auth.deviceNo);
                }); 
                for (var ii = 0; ii < cashbank.length; ii++) {
                    dispatch('actSyncDataCashBank', cashbank[ii].AccountID + "." + cashbank[ii].DeviceNo);
                }

                //get data usertablet
                var usertablet = localStorage.getItem("usertablet") ? JSON.parse(localStorage.getItem("usertablet")) : [];
                usertablet = usertablet.filter((utablet) => {
                    return (utablet.SynMode == "1" && utablet.DeviceNo == rootState.auth.deviceNo);
                }); 
                for (var iii = 0; iii < usertablet.length; iii++) {
                    dispatch('actSyncDataUserTablet', usertablet[iii].UserID + "." + usertablet[iii].DeviceNo);
                }

                dispatch('actSyncDataOptions');
                dispatch('actUploadMasterCustomer');
                dispatch('actUploadOpenCloseOutlet');
                dispatch('actCashBankin');
                dispatch('actCashBankout');
            }
        },
        actSyncDataSale({ rootState, dispatch }, data) {
            let TransactionID = data;
            //get data sale
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];
            pendingsale = pendingsale.filter((sale) => {
                return (sale.TransactionID + "." + sale.DeviceNo == TransactionID && sale.DeviceID == rootState.items.options[0].DeviceID);
            });
            //get data sale discount detail
            var pendingsalediscountdetail = localStorage.getItem("pendingsalediscountdetail") ? JSON.parse(localStorage.getItem("pendingsalediscountdetail")) : [];
            pendingsalediscountdetail = pendingsalediscountdetail.filter((sale) => {
                return (sale.TransactionID + "." + sale.TransactionDeviceNo == TransactionID && sale.DeviceID == rootState.items.options[0].DeviceID);
            });
            //get data sale item detail
            var pendingsaleitemdetail = localStorage.getItem("pendingsaleitemdetail") ? JSON.parse(localStorage.getItem("pendingsaleitemdetail")) : [];
            pendingsaleitemdetail = pendingsaleitemdetail.filter((sale) => {
                return (sale.TransactionID + "." + sale.TransactionDeviceNo == TransactionID && sale.DeviceID == rootState.items.options[0].DeviceID);
            });
			if (pendingsaleitemdetail.length < 400) {
				
            if (pendingsaleitemdetail.length > 0) {
                var pendingsaleitemdetailingredients = localStorage.getItem("pendingsaleitemdetailingredients") ? JSON.parse(localStorage.getItem("pendingsaleitemdetailingredients")) : [];
                var pendingsaleitemdetailtax = localStorage.getItem("pendingsaleitemdetailtax") ? JSON.parse(localStorage.getItem("pendingsaleitemdetailtax")) : [];
                var pendingsaleitemproduct = localStorage.getItem("pendingsaleitemproduct") ? JSON.parse(localStorage.getItem("pendingsaleitemproduct")) : [];
                pendingsaleitemdetail.forEach(function (itemDetail, i) {
                    //get data sale item detail ingredients
                    var pendingsaleitemdetailingredientsdata = pendingsaleitemdetailingredients.filter((itemingd) => {
                        return (itemingd.TransactionID + "." + itemingd.TransactionDeviceNo == TransactionID && itemingd.DetailID + "." + itemingd.DetailDeviceNo == itemDetail.DetailID + "." + itemDetail.DeviceNo && itemingd.DeviceID == rootState.items.options[0].DeviceID);
                    });
                    //get data sale item detail tax
                    var pendingsaleitemdetailtaxdata = pendingsaleitemdetailtax.filter((itemtax) => {
                        return (itemtax.TransactionID + "." + itemtax.TransactionDeviceNo == TransactionID && itemtax.DetailID + "." + itemtax.DetailDeviceNo == itemDetail.DetailID + "." + itemDetail.DeviceNo && itemtax.DeviceID == rootState.items.options[0].DeviceID);
                    });
                    if(pendingsaleitemdetailtaxdata) {
                        for (let i = 0; i < pendingsaleitemdetailtaxdata.length; i++) {
                            delete pendingsaleitemdetailtaxdata[i].id;
                        }
                    }
                    //get data sale item detail product
                    var pendingsaleitemproductdata = pendingsaleitemproduct.filter((itemprod) => {
                        return (itemprod.TransactionID + "." + itemprod.TransactionDeviceNo == TransactionID && itemprod.DetailID + "." + itemprod.DetailDeviceNo == itemDetail.DetailID + "." + itemDetail.DeviceNo && itemprod.DeviceID == rootState.items.options[0].DeviceID);
                    });

                    pendingsaleitemdetail[i].Details_Ingredients = pendingsaleitemdetailingredientsdata
                    pendingsaleitemdetail[i].Details_Modifier = []
                    pendingsaleitemdetail[i].Details_Tax = pendingsaleitemdetailtaxdata
                    pendingsaleitemdetail[i].Detail_Product = pendingsaleitemproductdata
                });
            }
            pendingsale[0].Details_Item = pendingsaleitemdetail
            pendingsale[0].Detail_DiningTable = []
            pendingsale[0].Detail_Discount = pendingsalediscountdetail
            delete pendingsale[0].SynMode;

            var getCRC32 = 0;
            return new Promise((resolve, reject) => {
                $.ajax({
                    //url: "http://localhost:70/ci-vue/get-crc32",
                    url: axios.defaults.baseURLWS + "wssale/getcrc32",
                    data: {
                        data: JSON.stringify(pendingsale[0]),
                    },
                    method: "POST",
                    success: (data) => {
                        getCRC32 = data
                        resolve(data)
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            }).then(function () {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        url: axios.defaults.baseURLWS + "wssale/synentitysale2",
                        data: {
                            data: JSON.stringify(pendingsale[0]),
                            devicesender: rootState.auth.deviceNo,
                            username: rootState.items.options[0].DeviceID,
                            checksum: getCRC32,
                            devid: rootState.auth.uuid,
                        },
                        method: "POST",
                        success: (data) => {
                            if (data.status == "OK") {
                                dispatch('actUpdateSynModeSale', data = {TransactionID: TransactionID, SynMode: '0'});
                                dispatch('downloaddataoptions')
                            }
                            resolve(data)
                        },
                        error: (error) => {
                            reject(error)
                        },
                    })
                });

            });
			
			} // pendingsaleitemdetail.length < 400
        },
        actSyncDataSaleDelete({ rootState, dispatch }, data) {
            let TransactionID = data;
            //get data saledelete
            var pendingsaledelete = localStorage.getItem("pendingsaledelete") ? JSON.parse(localStorage.getItem("pendingsaledelete")) : [];
            pendingsaledelete = pendingsaledelete.filter((sale) => {
                return (sale.TransactionID + "." + sale.DeviceNo == TransactionID && sale.DeviceID == rootState.items.options[0].DeviceID);
            });
            
            delete pendingsaledelete[0].SynMode;

            var getCRC32 = 0;
            return new Promise((resolve, reject) => {
                $.ajax({
                    //url: "http://localhost:70/ci-vue/get-crc32",
                    url: axios.defaults.baseURLWS + "wssale/getcrc32",
                    data: {
                        data: JSON.stringify(pendingsaledelete[0]),
                    },
                    method: "POST",
                    success: (data) => {
                        getCRC32 = data
                        resolve(data)
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            }).then(function () {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        url: axios.defaults.baseURLWS + "wssale/delentitysale",
                        data: {
                            data: JSON.stringify(pendingsaledelete[0]),
                            devicesender: rootState.auth.deviceNo,
                            username: rootState.items.options[0].DeviceID,
                            checksum: getCRC32,
                            devid: rootState.auth.uuid,
                        },
                        method: "POST",
                        success: (data) => {
                            if (data.status == "OK") {
                                dispatch('actUpdateSynModeSaleDelete', data = {TransactionID: TransactionID, SynMode: '0'});
                            }
                            resolve(data)
                        },
                        error: (error) => {
                            reject(error)
                        },
                    })
                });

            });
        },
        actUpdateSynModeSale({ rootState }, data) {
            let TransactionID = data.TransactionID;
            let SynMode = data.SynMode;
            //get data sale
            var pendingsale = localStorage.getItem("pendingsale") ? JSON.parse(localStorage.getItem("pendingsale")) : [];

            for (var i = 0; i < pendingsale.length; i++) {
                if(pendingsale[i].TransactionID + "." + pendingsale[i].DeviceNo == TransactionID && pendingsale[i].DeviceID == rootState.items.options[0].DeviceID){ 
                    pendingsale[i].SynMode = SynMode;
                    break;  
                }
             }

            localStorage.setItem("pendingsale", JSON.stringify(pendingsale));
        },
        actUpdateSynModeSaleDelete({ rootState, dispatch }, data) {
            let TransactionID = data.TransactionID;
            //let SynMode = data.SynMode;
            //get data saledelete
            var pendingsaledelete = localStorage.getItem("pendingsaledelete") ? JSON.parse(localStorage.getItem("pendingsaledelete")) : [];
            var filterPendingSaleDelete = pendingsaledelete.filter((el) => {
                return (el.TransactionID + "." + el.DeviceNo != TransactionID && el.DeviceID == rootState.items.options[0].DeviceID);
            });
            localStorage.setItem("pendingsaledelete", JSON.stringify(filterPendingSaleDelete));

            dispatch('downloadpendingsale');
            dispatch('downloadpendingsaleitemdetail');
            dispatch('downloadpendingsaleitemdetailingredients');
            dispatch('downloadpendingsaleitemproduct');
            dispatch('downloadpendingsaleitemdetailmodifier');
            dispatch('downloadpendingsalemodifierdetailingredients');
            dispatch('downloadpendingsaleitemdetailtax');
            dispatch('downloadpendingsalediningtabledetail');
            dispatch('downloadpendingsalediscountdetail');
        },
        actSyncDataCashBank({ rootState, dispatch }, data) {
            //get data cashbank
            var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
            cashbank = cashbank.filter((cash) => {
                return (cash.AccountID + "." + cash.DeviceNo == data && cash.SynMode == "1");
            });
            delete cashbank[0].SynMode;
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "ws/syncashbank",
                    data: {
                        data: JSON.stringify(cashbank[0]),
                        mode: "CREATE",
                        model: "0xG01",
                        devicesender: rootState.auth.deviceNo,
                        username: rootState.items.options[0].DeviceID,
                        devid: rootState.auth.uuid,
                    },
                    method: "POST",
                    success: (data) => {
                        if (data.status == "OK") {
                            dispatch('actUpdateSynModeCashBank', data = {SynMode: '0'});
                        }
                        resolve(data)
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            });
        },
        actUpdateSynModeCashBank({ rootState }, data) {
            var SynMode = data.SynMode;
            //get data cashbank
            var cashbank = localStorage.getItem("cashbank") ? JSON.parse(localStorage.getItem("cashbank")) : [];
    
            for (var i = 0; i < cashbank.length; i++) {
                if(cashbank[i].AccountID + "." + cashbank[i].DeviceNo == "1." + rootState.auth.deviceNo && cashbank[i].DeviceID == rootState.items.options[0].DeviceID){ 
                    cashbank[i].SynMode = SynMode;
                    break;  
                }
             }
    
            localStorage.setItem("cashbank", JSON.stringify(cashbank));
        },
        actSyncDataOptions({ rootState, dispatch }) {
            //get data options
            var options = localStorage.getItem("options") ? JSON.parse(localStorage.getItem("options")) : [];
            if(options.length > 0){
                if(options[0].SynMode == "1" || options[0].SynMode == "2" || options[0].SynMode == "3"){
                    options[0].CompanyLogoLink = "/nuta/CompanyLogo.jpg";
                    delete options[0].SynMode;
                    delete options[0].billing_type;
                    delete options[0].topup_balance;
                    delete options[0].topup_minimal;
                    delete options[0].warning_balance;
                    delete options[0].service_fee;
                    delete options[0].mynutapos_fee;
                    delete options[0].IsMyNutapos;
                    delete options[0].IsKDS;
                    delete options[0].FiturKdsAktifSampai;
                    delete options[0].IsLoyalty;
                    delete options[0].FiturLoyaltyAktifSampai;
                    delete options[0].IsAccounting;
                    delete options[0].AccountingAktifSampai;
                    delete options[0].ReferalCode;
                    delete options[0].TimezoneName;
                    delete options[0].HourOffsetUTC;
                    delete options[0].AllowDifferentDate;
                    delete options[0].TanggalJam;
                    delete options[0].basic_price_varian;
                    delete options[0].wa_daily_report_count;
                    return new Promise((resolve, reject) => {
                        $.ajax({
                            url: axios.defaults.baseURLWS + "ws/synoption",
                            data: {
                                data: JSON.stringify(options[0]),
                                mode: "UPDATE",
                                model: "0xK01",
                                devicesender: rootState.auth.deviceNo,
                                username: rootState.items.options[0].DeviceID,
                                devid: rootState.auth.uuid,
                            },
                            method: "POST",
                            success: (data) => {
                                if (data.status == "OK") {
                                    dispatch('actUpdateSynModeOptions', data = {SynMode: '0'});
                                }
                                resolve(data)
                            },
                            error: (error) => {
                                reject(error)
                            },
                        })
                    });
                }
                var lImage = localStorage.getItem("localimage") ? JSON.parse(localStorage.getItem("localimage")) : [];
                if(lImage.length > 0){
                    var filterImage = lImage.filter((sImage) => {
                        return (sImage.Table == "options" && sImage.TableID == "1" && sImage.SynMode == "1");
                    });
                    if(filterImage.length > 0){
                        var ext = filterImage[0].Image.split(';')[0].split('/')[1];
                        var image = filterImage[0].Image.split('base64,')[1];
                        return new Promise((resolve, reject) => {
                            $.ajax({
                                url: axios.defaults.baseURLWS + "ws/synlogo",
                                data: {
                                    id: "1",
                                    ext: ext,
                                    devid: rootState.items.options[0].DeviceID,
                                    varian: "Nuta",
                                    image: image,
                                },
                                method: "POST",
                                success: (data) => {
                                    if(data.status == "OK"){
                                        dispatch("downloadoptions")
                                        dispatch('actUpdateSynModeLocalImage', data = {SynMode: '0'});
                                    } 
                                    resolve(data)
                                },
                                error: (error) => {
                                    reject(error)
                                },
                            });
                        });
                    }
                }
                
            }
        },
        actUpdateSynModeOptions({ commit }, data) { // eslint-disable-line no-unused-vars
            let SynMode = data.SynMode;
            //get data options
            var options = localStorage.getItem("options") ? JSON.parse(localStorage.getItem("options")) : [];
            if(options.length > 0){
                options[0].SynMode = SynMode;
            }
    
            localStorage.setItem("options", JSON.stringify(options));
        },
        actUploadMasterCustomer({ rootState, dispatch }){
            //get data options
            var customer = localStorage.getItem("pelanggan") ? JSON.parse(localStorage.getItem("pelanggan")) : [];
            customer = customer.filter(customer => customer.SynMode != 0);
            for (let index = 0; index < customer.length; index++) {
                var mode = "";
                            
                if (customer[index].SynMode==1){
                    mode = "CREATE";
                }
                else if (customer[index].SynMode==2){
                    mode = "UPDATE";
                }
                delete customer[index].SynMode
                return new Promise((resolve, reject) => {
                    if (mode!=""){
                        $.ajax({
                            url: axios.defaults.baseURLWS + "ws/synmastercustomer",
                            data: {
                                data: JSON.stringify(customer[index]),
                                mode: mode,
                                model: "0xW01",
                                devicesender: rootState.auth.deviceNo,
                                username: rootState.items.options[0].DeviceID,
                                devid: rootState.auth.uuid,
                            },
                            method: "POST",
                            success: (data) => {
                                if (data.status == "OK") {
                                    dispatch('actUpdateSynModeCustomer', customer[index].CustomerID +"."+ customer[index].DeviceNo);
                                }
                                resolve(data)
                            },
                            error: (error) => {
                                reject(error)
                            },
                        })
                    }
                })
            }
        },
        actUpdateSynModeCustomer({ commit },data) { // eslint-disable-line no-unused-vars
            var customer = localStorage.getItem("pelanggan") ? JSON.parse(localStorage.getItem("pelanggan")) : [];
            if (customer.length > 0){
                for (var i = 0; i < customer.length; i++) {
                    if (customer[i].CustomerID +"."+ customer[i].DeviceNo == data)
                    {
                        customer[i].SynMode = 0;
                    }
                }
            }

            localStorage.setItem("pelanggan", JSON.stringify(customer));
        
        },
        actUploadOpenCloseOutlet({ rootState, dispatch }){
            var uploadopencloseoutlet = localStorage.getItem("opencloseoutlet") ? JSON.parse(localStorage.getItem("opencloseoutlet")) : [];
            uploadopencloseoutlet = uploadopencloseoutlet.filter((openData) => {
                return ((openData.SynMode == "1" || openData.SynMode == "2") && openData.DeviceNo == rootState.auth.deviceNo);
            });
            for (let index = 0; index < uploadopencloseoutlet.length; index++) {
                var mode = "";
                if (uploadopencloseoutlet[index].SynMode == "1"){
                    mode = "CREATE";
                }
                else if (uploadopencloseoutlet[index].SynMode == "2"){
                    mode = "UPDATE";
                }
                delete uploadopencloseoutlet[index].SynMode
                return new Promise((resolve, reject) => {
                    if (mode!=""){
                        $.ajax({
                            url: axios.defaults.baseURLWS + "ws/synopencloseoutlet",
                            data: {
                                data: JSON.stringify(uploadopencloseoutlet[index]),
                                mode: mode,
                                model: "0xQ01",
                                devicesender: rootState.auth.deviceNo,
                                username: rootState.items.options[0].DeviceID,
                                devid: rootState.auth.uuid,
                            },
                            method: "POST",
                            success: (data) => {
                                if (data.status == "OK") { 
                                    dispatch('actUpdateSynModeOpenCloseOutlet', uploadopencloseoutlet[index].OpenID +"."+ uploadopencloseoutlet[index].DeviceNo);
                                }
                                resolve("berhasil")
                            },
                            error: (error) => {
                                reject(error)
                            },
                        })
                    }
                })
            }
        },
        actUpdateSynModeOpenCloseOutlet({ commit }, dataOpenID) { // eslint-disable-line no-unused-vars
            //get data options
            var opencloseoutlet = localStorage.getItem("opencloseoutlet") ? JSON.parse(localStorage.getItem("opencloseoutlet")) : [];
            if (opencloseoutlet.length > 0){
                for (var i = 0; i < opencloseoutlet.length; i++) {
                    if (opencloseoutlet[i].OpenID +"."+ opencloseoutlet[i].DeviceNo == dataOpenID)
                    {
                        opencloseoutlet[i].SynMode = 0;
                    }
                }
            }

            localStorage.setItem("opencloseoutlet", JSON.stringify(opencloseoutlet));
        },
        actCashBankin({ rootState, dispatch }){
            var cashbankin = localStorage.getItem("cashbankin") ? JSON.parse(localStorage.getItem("cashbankin")) : [];
            cashbankin = cashbankin.filter((cbin) => {
                return (cbin.SynMode == "1" || cbin.SynMode == "2");
            }); 
            for (let index = 0; index < cashbankin.length; index++) {
                var mode = "";
                if (cashbankin[index].SynMode==1){
                    mode = "CREATE";
                }
                else if (cashbankin[index].SynMode==2){
                    mode = "UPDATE";
                }
                
                delete cashbankin[index].SynMode
                return new Promise((resolve, reject) => {
                    if (mode!=""){
                        $.ajax({
                            url: axios.defaults.baseURLWS + "ws/syncashbankin",
                            data: {
                                data: JSON.stringify(cashbankin[index]),
                                mode: mode,
                                model: "0xJ01",
                                devicesender: rootState.auth.deviceNo,
                                username: rootState.items.options[0].DeviceID,
                            },
                            method: "POST",
                            success: (data) => {
                                if (data.status == "OK") {
                                    dispatch('actUpdateSynModeCashBankIn', data = cashbankin[index]);
                                }
                                resolve("berhasil")
                            },
                            error: (error) => {
                                reject(error)
                            },
                        })
                    }
                })
            }
        },
        actUpdateSynModeCashBankIn({ commit }, data) { // eslint-disable-line no-unused-vars
            var datacashbankin = localStorage.getItem("cashbankin") ? JSON.parse(localStorage.getItem("cashbankin")) : [];
            if (datacashbankin.length > 0){
                for (var i = 0; i < datacashbankin.length; i++) {
                    if (datacashbankin[i].TransactionNumber == data.TransactionNumber)
                    {
                        datacashbankin[i].SynMode = 0;
                    }
                }
            }
            localStorage.setItem("cashbankin", JSON.stringify(datacashbankin));
        },
        actCashBankinDelete({ rootState, dispatch }) {
            var cashbankin = localStorage.getItem("cashbankin") ? JSON.parse(localStorage.getItem("cashbankin")) : [];
            cashbankin = cashbankin.filter((cbin) => {
                return (cbin.SynMode == "3");
            }); 
            for (let index = 0; index < cashbankin.length; index++) {
                var mode = "DELETE";
                return new Promise((resolve, reject) => {
                    if (mode!="") {
                        $.ajax({
                            url: axios.defaults.baseURLWS + "ws/synrecycle2",
                            data: {
                                id: cashbankin[index].TransactionID,
                                devno: cashbankin[index].DeviceNo,
                                devid: rootState.items.options[0].DeviceID,
                                varian: 'Nuta',
                                mode: mode,
                                model: "0xJ01",
                                devicesender: rootState.auth.deviceNo,
                            },
                            method: "POST",
                            success: (data) => {
                                if (data.status == "OK") {
                                    dispatch('actDeleteSynModeCashBankIn', cashbankin[index]);
                                } 
                                resolve({status: "success"});
                            },
                            error: (error) => {
                                reject(error)
                            },
                        })
                    }
                })
            }
        },
        actDeleteSynModeCashBankIn({ commit }, data) { // eslint-disable-line no-unused-vars
            var datacashbankin = localStorage.getItem("cashbankin") ? JSON.parse(localStorage.getItem("cashbankin")) : [];
            if (datacashbankin.length > 0){
                datacashbankin = datacashbankin.filter((cbin) => {
                    return (cbin.TransactionID != data.TransactionID && cbin.DeviceNo != data.DeviceNo);
                });
            }
            localStorage.setItem("cashbankin", JSON.stringify(datacashbankin));
        },
        actCashBankout({ rootState, dispatch }){
            var cashbankout = localStorage.getItem("cashbankout") ? JSON.parse(localStorage.getItem("cashbankout")) : [];
            cashbankout = cashbankout.filter((cbout) => {
                return (cbout.SynMode == "1" || cbout.SynMode == "2");
            }); 
            for (let index = 0; index < cashbankout.length; index++) {
                var mode = "";
                if (cashbankout[index].SynMode==1){
                    mode = "CREATE";
                }
                else if (cashbankout[index].SynMode==2){
                    mode = "UPDATE";
                }
                
                delete cashbankout[index].SynMode
                return new Promise((resolve, reject) => {
                    if (mode!=""){
                        $.ajax({
                            url: axios.defaults.baseURLWS + "ws/syncashbankout",
                            data: {
                                data: JSON.stringify(cashbankout[index]),
                                mode: mode,
                                model: "0xH01",
                                devicesender: rootState.auth.deviceNo,
                                username: rootState.items.options[0].DeviceID,
                            },
                            method: "POST",
                            success: (data) => {
                                if (data.status == "OK") {
                                    dispatch('actUpdateSynModeCashBankOut', data = cashbankout[index]);
                                }
                                resolve("berhasil")
                            },
                            error: (error) => {
                                reject(error)
                            },
                        })
                    }
                })
            }
        },
        actUpdateSynModeCashBankOut({ commit }, data) { // eslint-disable-line no-unused-vars
            var datacashbankout = localStorage.getItem("cashbankout") ? JSON.parse(localStorage.getItem("cashbankout")) : [];
            if (datacashbankout.length > 0){
                for (var i = 0; i < datacashbankout.length; i++) {
                    if (datacashbankout[i].TransactionNumber == data.TransactionNumber)
                    {
                        datacashbankout[i].SynMode = 0;
                    }
                }
            }
            localStorage.setItem("cashbankout", JSON.stringify(datacashbankout));
        },
        actCashBankoutDelete({ rootState, dispatch }) {
            var cashbankout = localStorage.getItem("cashbankout") ? JSON.parse(localStorage.getItem("cashbankout")) : [];
            cashbankout = cashbankout.filter((cbin) => {
                return (cbin.SynMode == "3");
            }); 
            for (let index = 0; index < cashbankout.length; index++) {
                var mode = "DELETE";
                return new Promise((resolve, reject) => {
                    if (mode!="") {
                        $.ajax({
                            url: axios.defaults.baseURLWS + "ws/synrecycle2",
                            data: {
                                id: cashbankout[index].TransactionID,
                                devno: cashbankout[index].DeviceNo,
                                devid: rootState.items.options[0].DeviceID,
                                varian: 'Nuta',
                                mode: mode,
                                model: "0xH01",
                                devicesender: rootState.auth.deviceNo,
                            },
                            method: "POST",
                            success: (data) => {
                                if (data.status == "OK") {
                                    dispatch('actDeleteSynModeCashBankOut', cashbankout[index]);
                                } 
                                resolve({status: "success"});
                            },
                            error: (error) => {
                                reject(error)
                            },
                        })
                    }
                })
            }
        },
        actDeleteSynModeCashBankOut({ commit }, data) { // eslint-disable-line no-unused-vars
            var datacashbankout = localStorage.getItem("cashbankout") ? JSON.parse(localStorage.getItem("cashbankout")) : [];
            if (datacashbankout.length > 0){
                datacashbankout = datacashbankout.filter((cbin) => {
                    return (cbin.TransactionID != data.TransactionID && cbin.DeviceNo != data.DeviceNo);
                });
            }
            localStorage.setItem("cashbankout", JSON.stringify(datacashbankout));
        },
        actUpdateSynModeLocalImage({ commit }, data) { // eslint-disable-line no-unused-vars
            let SynMode = data.SynMode;
            var lImage = localStorage.getItem("localimage") ? JSON.parse(localStorage.getItem("localimage")) : [];
    
            for (var i = 0; i < lImage.length; i++) { 
                if(lImage[i].Table == "options" && lImage[i].TableID == "1"){ 
                    lImage[i].SynMode = SynMode;
                    break;  
                }
             }
    
            localStorage.setItem("localimage", JSON.stringify(lImage));
        },
        actSyncDataUserTablet({ rootState, dispatch }, data) {
            //get data usertablet
            var usertablet = localStorage.getItem("usertablet") ? JSON.parse(localStorage.getItem("usertablet")) : [];
            usertablet = usertablet.filter((utablet) => {
                return (utablet.UserID + "." + utablet.DeviceNo == data && (utablet.SynMode == "1" || utablet.SynMode == "2"));
            });
            var mode = "CREATE"
            if(usertablet[0].SynMode == "1"){
                mode = "CREATE"
            } else if(usertablet[0].SynMode == "2"){
                mode = "UPDATE"
            }
            delete usertablet[0].SynMode;
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: axios.defaults.baseURLWS + "ws/synusertablet",
                    data: {
                        data: JSON.stringify(usertablet[0]),
                        mode: mode,
                        model: "0xM01",
                        devicesender: rootState.auth.deviceNo,
                        username: rootState.items.options[0].DeviceID,
                        devid: rootState.auth.uuid,
                    },
                    method: "POST",
                    success: (data) => {
                        if (data.status == "OK") {
                            dispatch('actUpdateSynModeUserTablet', data = {UserID: usertablet[0].UserID + "." + usertablet[0].DeviceNo, SynMode: '0'});
                        }
                        resolve(data)
                    },
                    error: (error) => {
                        reject(error)
                    },
                })
            });
        },
        actUpdateSynModeUserTablet({ rootState }, data) {
            let UserID = data.UserID;
            let SynMode = data.SynMode;
            //get data usertablet
            var usertablet = localStorage.getItem("usertablet") ? JSON.parse(localStorage.getItem("usertablet")) : [];

            for (var i = 0; i < usertablet.length; i++) {
                if(usertablet[i].UserID + "." + usertablet[i].DeviceNo == UserID && usertablet[i].DeviceID == rootState.items.options[0].DeviceID){ 
                    usertablet[i].SynMode = SynMode;
                    break;  
                }
             }

            localStorage.setItem("usertablet", JSON.stringify(usertablet));
        },
    },
    mutations: {
    }
})