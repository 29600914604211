<template>
  <router-view />
</template>
<script>
import { mapGetters, mapActions } from "vuex";
//import { onMessage, getToken } from "firebase/messaging";
export default {
  data() {
    return {
      invervalTimer: "",
    };
  },
  methods: {
    ...mapActions(["setConnection", "actReSyncDataSale"]),
    cekConnection(el) {
      var res = "";
      if (el) {
        res = "Online";
        this.invervalTimer = setInterval(() => {
          if (this.isLoggedIn == "true" || this.isLoggedIn == true) {
            if (
              this.isLoggedInKasir == "false" ||
              this.isLoggedInKasir == false
            ) {
              this.$store.dispatch("downloadusertablet");
              console.log("view App - run downloadusertablet");
            }
            //this.$store.dispatch("actReSyncDataSale");
            //console.log("view App - run actReSyncDataSale");
          }
        }, 20000);
      } else {
        res = "Offline";
        clearInterval(this.invervalTimer);
      }
      this.$store.dispatch("setConnection", res);
    },

    /*receiveMessage() {
      try {
        onMessage(this.$messaging, (payload) => {
          console.log("payload ", payload);
        });
      } catch (e) {
        console.log(e);
      }
    },*/
  },
  computed: {
    ...mapGetters(["isLoggedIn", "isLoggedInKasir"]),
  },
  watch: {},
  /*created() {
    getToken(this.$messaging, {
      vapidKey:
        "BPMVGexC-VQmHnjx6M1ZeyZHNP66VvUDpyIthKQ9igFwRE273cVV1BcqwmiW8f7itMbOb_5RHF7tAJmqaGtXRKw",
    }).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          console.log("the token",currentToken);
          this.receiveMessage();
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  },*/
  mounted() {
    document.title = "Kasir";
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.$router.push({ name: "Forbidden" });
    }
    window.addEventListener("load", () => {
      // 1st, we set the correct status when the page loads
      navigator.onLine ? this.cekConnection(true) : this.cekConnection(false);
      // now we listen for network status changes
      window.addEventListener("online", () => {
        this.cekConnection(true);
      });
      window.addEventListener("offline", () => {
        this.cekConnection(false);
      });
    });

    window.onpopstate = (event) => {// eslint-disable-line no-unused-vars
      if (
        window.localStorage.getItem("isOpenOutlet") === "true" &&
        this.$route.path == "/openoutlet"
      ) {
        this.$router.push("kasir");
      }
    };
  },
};
</script>
<style>
@import "./assets/css/layout.css";
@import "./assets/css/common-styles.min.css";
@import "./assets/css/theme.css";
@import "./assets/css/custom.css";
@import "./assets/css/nuta.css";
@import "./assets/fontawesome/css/all.css";
@import "./assets/css/select2.min.css";
</style>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.btn-green {
  background-color: #00ae2b !important;
}
.btn-green:hover {
  border-color: #00ae2b !important;
  background-color: #019b28 !important;
}
.btn-link {
  color: #00ae2b !important;
}
.btn-link:hover {
  color: #019b28 !important;
}
</style>
